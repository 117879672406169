import React, { ChangeEvent, Component, useEffect, useMemo, useState } from 'react';
import Markdown from 'react-markdown';
import { ApplicationLink } from "./Routing";
import { CopyComponent, FantasyModal } from "./TypescriptComponents";
import { HTTP_CLIENT, HTTP_CLIENT_JQUERY_ADAPTER } from "./Util";
import { SelectionSelector } from "./AthleteApp";
import { Athlete, OwnedAthlete, Race, Selection, Sidebet, SidebetOption, Weekend } from "./models";
import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    Header,
    Row,
    TableOptions,
    useReactTable
} from "@tanstack/react-table";
import Skeleton from "react-loading-skeleton";
import { useLoaderData } from "react-router-dom";
import remarkGfm from "remark-gfm";

const SidebetList = ({ sidebets }: { sidebets: Sidebet[] }) => (
    <div>
        { sidebets && sidebets.length === 1 && <h3>Sidebet:</h3> }
        { sidebets && sidebets.length > 1 && <h3>Sidebets</h3> }
        { sidebets && sidebets.map((sidebet) => {
            return <SidebetComponent key={ sidebet.sidebet_id } sidebet={ sidebet }
                                     disabled={ new Date(sidebet.betting_close) < new Date() }/>;
        }) }
    </div>
);

class SidebetComponent extends Component<any, any> {
    constructor(props: any) {
        super(props);

        this.handleClick = this.handleClick.bind(this);

        this.state = {
            bet_description: '',
            options: [],
            selectedOption: {}
        }
    }

    componentDidMount() {
        HTTP_CLIENT_JQUERY_ADAPTER.get({
            url: `/api/me/placed_sidebets/${ this.props.sidebet.sidebet_id }`,
            success: (data) => {
                this.setState({
                    selectedOption: data.option_id
                })
            }
        });
    }

    handleClick(event: ChangeEvent<HTMLInputElement>) {
        this.setState({
            selectedOption: parseInt(event.target.value)
        });

        HTTP_CLIENT_JQUERY_ADAPTER.put({
            url: `/api/me/placed_sidebets/${ this.props.sidebet.sidebet_id }`,
            data: {
                option_id: parseInt(event.target.value)
            }
        });

        return true;
    }

    render() {
        const sidebet: Sidebet = this.props.sidebet;
        const options: SidebetOption[] = sidebet.options.sort((a, b) => a.option_id - b.option_id)
        return (
            <div className="card">
                <div className="card-divider">
                    <strong><Markdown remarkPlugins={[remarkGfm]}>{ sidebet.description }</Markdown> (for { sidebet.value } points)</strong>
                </div>
                <div className="card-section">
                    { options.map((option) =>
                        <label className="sidebet-option"
                               htmlFor={ "option_" + option.option_id }
                               key={ option.option_id }>
                            <input type="radio"
                                   name="sidebet_option"
                                   value={ option.option_id }
                                   id={ "option_" + option.option_id }
                                   onChange={ this.handleClick }
                                   disabled={ this.props.disabled }
                                   checked={ this.state.selectedOption === option.option_id }/>
                            { option.description }
                        </label>
                    ) }
                </div>
            </div>
        )
    }
}

const RaceComponent = ({race}: {race: Race}) => (
    <li>
        { new Date(race.date).toLocaleDateString(
            navigator.language,
            {
                month: 'numeric',
                day: 'numeric',
                timeZone: "UTC"
            }
        ) }:&nbsp;
        <ApplicationLink href={ '/races/' + race.race_id }>
            { race.distance !== null && race.name !== 'Sprint' && race.distance + ' ' }
            { race.discipline && ['Classic', 'Freestyle'].includes(race.discipline) && race.discipline + ' ' }
            { race.gender === 'mixed' && 'Mixed ' }
            { race.name }
        </ApplicationLink>
    </li>
);

const TourInfo = ({ selectionsCount }: { selectionsCount: number }) => {
    const intToText = [null, 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];

    return <div className="row">
        <div className="medium-12 columns">
            <p>
                This event is a tour with { intToText[selectionsCount - 1] } legs, you can pick a different team for
                each
                leg and one team for the overall result. The points awarded for a tour leg is about half of a normal
                World Cup race, while the final tour result is worth a lot more than that. You can find the exact point
                distributions in the <a
                href="https://assets.fis-ski.com/image/upload/v1571408312/fis-prod/assets/Rules_WC_CC_1920_oct2019_all.pdf">FIS
                World Cup Rules</a>.
            </p>
            <p>
                To avoid having to pick a completely new team, you can copy teams from other tour legs. Athlete prices
                won't change between tour legs.
            </p>
        </div>
    </div>
};

const sellAthlete = (athlete: Athlete, selection: Selection) => {
    return HTTP_CLIENT_JQUERY_ADAPTER.delete({
        url: `/api/me/teams/selections/${ selection?.selection_id }/${ athlete.athlete_id }`
    });
}

const buyAthlete = (athlete: Athlete, selection: Selection): Promise<OwnedAthlete> => {
    return HTTP_CLIENT_JQUERY_ADAPTER.post<OwnedAthlete>({
        url: `/api/me/teams/selections/${ selection?.selection_id }`,
        data: {
            athlete_id: athlete.athlete_id
        },
        contentType: 'application/json'
    });
}


export interface AthletesAppLoadedData {
    budget: number;
    upcomingWeekend: Weekend;
    loadedAfterDeadline: boolean;
    loadedActiveSelection: Selection;
    athletes: Athlete[];
}

export const AthletesApp = (props: any) => {

    const {
        budget,
        upcomingWeekend,
        loadedAfterDeadline,
        loadedActiveSelection,
        athletes
    } = useLoaderData() as AthletesAppLoadedData;
    const [ownedAthletes, setOwnedAthletes] = useState<OwnedAthlete[]>([]);
    const [afterDeadline, setAfterDeadline] = useState<boolean>(loadedAfterDeadline);
    const [activeSelection, setActiveSelection] = useState<Selection>(loadedActiveSelection);
    const [tradingDeadline, setTradingDeadline] = useState<string>("");
    const [intervalId, setInvervalId] = useState<NodeJS.Timeout | number | null>(null);
    const [clonedSelection, setClonedSelection] = useState<string | null>(null);
    const balance = useMemo(() => {
        const totalCost = ownedAthletes.reduce((acc, current) => (acc + current.bought_for), 0);
        return budget - totalCost;
    }, [budget, ownedAthletes]);

    useEffect(() => {
        HTTP_CLIENT.get<OwnedAthlete[]>(`/api/me/teams/selections/${ activeSelection.selection_id }`)
            .then((response) => {
                const team = response.data;
                setOwnedAthletes(team);
                setTradingDeadline(activeSelection.trading_close);
                setAfterDeadline(!activeSelection.trading_open);
            });
        if (intervalId != null) {
            setInvervalId(setInterval(updateDeadlineStatus, 1000));
        }
    }, [activeSelection]);

    const flagAsCaptain = (athlete: OwnedAthlete) => {
        if (athlete.is_team_captain) {
            return;
        }
        HTTP_CLIENT.put(`/api/me/athletes/${ athlete.athlete_id }/make_team_captain`, {})
            .then(() => {
                setOwnedAthletes(alreadyOwned => {
                    const copyOwned = JSON.parse(JSON.stringify(alreadyOwned));
                    copyOwned.forEach((ath: OwnedAthlete) =>
                        ath.is_team_captain = ath.athlete_id === athlete.athlete_id);
                    return copyOwned;
                });
            })
    }

    const updateAthleteSelectStatus = (athlete: Athlete, status: boolean) => {
        let selectedAthletes: OwnedAthlete[];
        if (status) {
            buyAthlete(athlete, activeSelection)
                .then((data) => {
                    setOwnedAthletes(alreadyOwned => {
                        selectedAthletes = alreadyOwned.slice();
                        selectedAthletes.push(data);
                        return selectedAthletes;
                    });
                })
        } else {
            sellAthlete(athlete, activeSelection)
                .then(() => {
                    setOwnedAthletes(alreadyOwned => {
                        return alreadyOwned.filter((ownedAthlete: OwnedAthlete) =>
                            ownedAthlete.athlete_id !== athlete.athlete_id
                        );
                    });
                });
        }
    }

    const updateDeadlineStatus = () => {
        const nextSelection = upcomingWeekend.selections.filter(
            selection => selection.selection_id === activeSelection.selection_id
        )[0];

        const currentDate = new Date();
        const tradingCloseDate = new Date(nextSelection.trading_close);

        if (!afterDeadline && tradingCloseDate <= currentDate) {
            setAfterDeadline(true);
            if (intervalId != null) {
                clearInterval(intervalId);
            }
        }
    }

    const handleSwitchActiveSelection = (event: Event, newSelection: Selection) => {
        event.preventDefault();
        setActiveSelection(newSelection);
        return false;
    }

    const acceptClone = () => {
        HTTP_CLIENT_JQUERY_ADAPTER.put<OwnedAthlete[]>({
            url: `/api/me/teams/selections/${ clonedSelection }/clone`,
            data: activeSelection,
            success: (team) => {
                setOwnedAthletes(team);
            }
        });
        closeWindow();
    }

    const closeWindow = () => {
        setClonedSelection(null);
    }

    const renderRaces = (selections: Selection[], gender: string) => {
        return selections.sort((a, b) => new Date(a.trading_close).getDate() - new Date(b.trading_close).getDate())
            .map((selection) => {
                return selection.races.filter(race => race.gender === gender)
                    .sort((a, b) => new Date(a.date).getDate() - new Date(b.date).getDate())
                    .map((race) => {
                        return (<RaceComponent key={ race.race_id } race={ race } />)
                    })
            })
    }


    const handleClone = (event: Event, selectionId: string) => {
        event.preventDefault();
        setClonedSelection(selectionId);
        return false;
    }


    const weekend = upcomingWeekend;
    return (
        <div>
            <FantasyModal isOpen={ !!clonedSelection }
                          close={ (accepted => accepted ? acceptClone() : closeWindow()) }
                          title="Copy Team"
                          message="Are you sure you want to replace you current team with the team from this race?"/>
            <h2>Upcoming event: { weekend.weekend_name }</h2>
            { weekend && !weekend.selections.length
                && <div>Trading Closes: { tradingDeadline.toLocaleString() }</div> }
            <div className="row">
                <div className="medium-6 columns">
                    <div className="card">
                        <div className="card-divider">
                            <strong>
                                Races this weekend&nbsp;
                            </strong>
                        </div>
                        { weekend.hasGenderRestrictedRaces() &&
                            <div className="row card-section">
                                <div className="small-6 columns">
                                    <strong>Men:</strong>
                                    <ul className="no-bullet">
                                        { weekend && renderRaces(weekend.selections, 'm') }
                                    </ul>
                                </div>
                                <div className="small-6 columns">
                                    <strong>Women:</strong>
                                    <ul className="no-bullet">
                                        { weekend && renderRaces(weekend.selections, 'f') }
                                    </ul>
                                </div>
                            </div>
                        }
                        { weekend.hasMixedRaces() &&
                            <div className="row card-section">
                                <div className="small-12 columns">
                                    <strong>Mixed:</strong>
                                    <ul className="no-bullet">
                                        { weekend && renderRaces(weekend.selections, 'mixed')}
                                    </ul>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="medium-6 columns">
                    <SidebetList sidebets={ weekend.sidebets }/>
                </div>
            </div>
            { weekend && weekend.is_tour &&
                <TourInfo selectionsCount={ weekend.selections.length }/>
            }
                <CopyComponent copyKey={'athletes'} />

            { weekend && activeSelection && weekend.selections.length > 1 &&
                <SelectionSelector selections={ weekend.selections }
                                   activeSelectionId={ activeSelection.selection_id }
                                   handleSwitchActiveSelection={ handleSwitchActiveSelection }
                                   handleClone={ handleClone }/>
            }

            {
                activeSelection && props.children({
                    updateAthleteSelectStatus: updateAthleteSelectStatus,
                    flagAsCaptain: (athlete: OwnedAthlete) => flagAsCaptain(athlete),
                    balance,
                    ownedAthletes,
                    afterDeadline,
                    athletes,
                    activeSelection
                })
            }
        </div>
    )
}

export const TradeButton = ({ afterDeadline, isOwned, athlete, allowBuying, selectAthlete }: any) => {
    if (afterDeadline) {
        return null;
    } else if (isOwned) {
        return <button className="button"
                       onClick={ () => selectAthlete(athlete, false) }>Remove</button>
    } else {
        return <button className="button"
                       onClick={ () => selectAthlete(athlete, true) }
                       disabled={ !allowBuying }>Add</button>
    }
};

export interface FilterConfig<DataClass, FilterClass> {
    filterFunction: (
        row: Row<DataClass>,
        filterValue: FilterClass
    ) => boolean,
    initialFilter?: FilterClass,
    filterUpdate?: (filter: FilterClass) => void;
    createFilterComponent: (globalFilter: FilterClass | undefined, setGlobalFilter: (filter: FilterClass) => void) => React.JSX.Element;
}

export const ReactTable = <DataClass, FilterClass>({ columns, data, filterConfig, pageSize }: {
    columns: ColumnDef<DataClass>[],
    data: DataClass[],
    filterConfig?: FilterConfig<DataClass, FilterClass>,
    pageSize?: number
}) => {

    const [filter, setFilter] = useState<FilterClass | undefined>(filterConfig?.initialFilter)

    const options: TableOptions<DataClass> = useMemo(() => {
        return {
            data,
            columns,
            initialState: {
                pagination: pageSize ? {
                    pageSize: pageSize
                } : undefined
            },
            state: {
                globalFilter: filter
            },
            enableGlobalFilter: !!filterConfig,
            getCoreRowModel: getCoreRowModel(),
            getSortedRowModel: getSortedRowModel(),
            onGlobalFilterChange: (filter: FilterClass) => {
                setFilter(filter);
                if (filterConfig?.filterUpdate) {
                    filterConfig.filterUpdate(filter);
                }
            },
            globalFilterFn: (
                row: Row<DataClass>,
                _: string,
                filterValue: FilterClass,
            ) => {
                if (filterConfig && filterValue !== undefined) {
                    return filterConfig.filterFunction(row, filterValue);
                }
                return true;
            },
            getColumnCanGlobalFilter: () => !!filterConfig
        }
    }, [data, columns, pageSize, filter, filterConfig]);
    if (filterConfig) {
        options.getFilteredRowModel = getFilteredRowModel();
    }
    if (pageSize) {
        options.getPaginationRowModel = getPaginationRowModel();
    }
    const {
        setGlobalFilter,
        nextPage,
        previousPage,
        setPageIndex,
        getCanNextPage: canNextPage,
        getCanPreviousPage: canPreviousPage,
        getHeaderGroups,
        getRowModel,
        getState: getTableState,
        getPageCount,
    } = useReactTable<DataClass>(
        options
    );

    function getClassName(header: Header<any, any>) {
        let className = `column_${ header.id } table_column`;
        if (header.column.getCanSort()) {
            className += " sortable";
        }
        if (header.column.getIsSorted()) {
            className += " sorted_" + header.column.getIsSorted();
        }
        return className;
    }

    const updateFilter = useMemo(() => (filter: FilterClass) => {
        setGlobalFilter(filter);
        setPageIndex(0);
    }, [setGlobalFilter, setPageIndex])

    return (
        <div>
            { filterConfig && filterConfig.createFilterComponent(filter, updateFilter) }
            <table className="table">
                <thead>
                { getHeaderGroups().map(headerGroup => (
                    <tr key={ headerGroup.id } id={ headerGroup.id }>
                        { headerGroup.headers.map(header => (
                            <th key={ header.id } id={ header.id } className={ getClassName(header) } onClick={ () => {
                                header.column.getCanSort() && header.column.toggleSorting()
                            } }>
                                {
                                    flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )
                                }
                            </th>
                        )) }
                    </tr>
                )) }
                </thead>
                <tbody>
                { getRowModel().rows.map((row) => {
                    return (
                        <tr key={ row.id }>
                            {
                                row.getVisibleCells().map(cell => <td className={ "column_" + cell.column.id }>
                                        {
                                            flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )
                                        }
                                    </td>
                                )
                            }
                        </tr>
                    )
                }) }
                </tbody>
            </table>
            <PaginationController gotoPage={ setPageIndex }
                                  canNextPage={ canNextPage }
                                  nextPage={ nextPage }
                                  canPreviousPage={ canPreviousPage }
                                  previousPage={ previousPage }
                                  pageIndex={ getTableState().pagination.pageIndex }
                                  pageCount={ getPageCount() }/>
        </div>
    )
};

const PaginationLink = ({
                            currentIndex,
                            linkIndex,
                            gotoPage
                        }: any) => {
    return (
        <li className={ `paginate_button ${ currentIndex === linkIndex - 1 ? "current" : "" }` }>
            { (currentIndex !== linkIndex - 1 && <button className={ 'clear link' }
                                                         onClick={ () => gotoPage(linkIndex - 1) }>{ linkIndex }</button>) || linkIndex }
        </li>
    )
};

const PaginationController = ({
                                  pageIndex,
                                  pageCount,
                                  gotoPage,
                                  canPreviousPage,
                                  previousPage,
                                  canNextPage,
                                  nextPage
                              }: any) => {
    const maxLinks = 5;
    const paginationMenu = ['1'];

    if (pageCount > 1 && pageCount <= maxLinks + 2) {
        Array.from(Array(pageCount - 1)).forEach((x, i) => {
            paginationMenu.push(i + 2 + '');
        });
    } else if (pageCount > maxLinks + 2) {
        if (pageIndex < maxLinks - 1) {
            Array.from(Array(maxLinks - 1)).forEach((x, i) => {
                paginationMenu.push(i + 2 + '');
            });
        }

        if (pageIndex > maxLinks - 2) {
            paginationMenu.push('...');
        }

        if (pageIndex >= maxLinks - 1 && pageIndex <= pageCount - maxLinks) {
            paginationMenu.push(pageIndex);
            paginationMenu.push(pageIndex + 1);
            paginationMenu.push(pageIndex + 2);
        }

        if (pageIndex < pageCount - maxLinks + 1) {
            paginationMenu.push('...');
        }

        if (pageIndex >= pageCount - maxLinks + 1) {
            Array.from(Array(maxLinks - 1)).forEach((x, i) => {
                const index = pageCount - maxLinks + i;
                paginationMenu.push(index + 1 + '');
            });
        }
        paginationMenu.push(pageCount);
    }

    return (
        <div className="float-right">
            <ul className="pagination">
                <li className={ `paginate_button previous ${ !canPreviousPage ? "disabled" : "" }` }>
                    { (canPreviousPage &&
                            <button className={ 'clear link' } onClick={ () => previousPage() }>Previous</button>) ||
                        <p>Previous</p> }
                </li>

                { paginationMenu.map((page, i) =>
                    <PaginationLink key={ `page-${ i }` } currentIndex={ pageIndex } linkIndex={ page }
                                    gotoPage={ gotoPage }/>
                ) }

                <li className={ `paginate_button next ${ !canNextPage ? "disabled" : "" }` }>
                    { (canNextPage && <button className={ 'clear link' } onClick={ () => nextPage() }>Next</button>) ||
                        <p>Next</p> }
                </li>
            </ul>
        </div>
    )
};

export const LoadingContainer = <T, >({ data, mapper }: {
    data: T | undefined,
    mapper: (t: T) => string | number | React.JSX.Element | React.JSX.Element[]
}) => {
    if (!data) {
        return <Skeleton/>;
    }
    return mapper(data);
}
